import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";
import type { AppProps } from "next/app";
import { env } from "donation-form/src/env";
import { Sentry } from "donation-form/src/utils/sentry";

export default function DonationFormApp({ Component, pageProps }: AppProps) {
  if (env.isBrowser && env.isProd) {
    LogRocket.init("alcw3f/donation-forms");
    LogRocket.getSessionURL(sessionURL => {
      Sentry.configureScope(scope => {
        scope.setExtra("sessionURL", sessionURL);
      });
    });
    setupLogRocketReact(LogRocket);
  }

  return <Component {...pageProps} />;
}
